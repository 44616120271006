import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>
      페이지를 찾을 수 없습니다. <br />
      포스트가 삭제 되었을 수 있습니다.
    </p>
  </Layout>
);

export default NotFoundPage;
